@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
@import-normalize;
body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR',
    'Malgun Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  overflow: overlay;
  background-color: #f6f6f6;
  &.modal {
    overflow: hidden;
  }
}
* {
  font-family: 'Pretendard', sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

input,
textarea,
button,
select,
a,
div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  min-height: -webkit-fill-available;
}
input {
  caret-color: #2f6ce4;
  border-radius: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  color: inherit;
}
button {
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
}
body {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a8b3c6;
    border-radius: 15px;
    border: 10px solid rgba(0, 0, 0, 0.4);
    background-clip: padding-box;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a8b3c6;
    border-radius: 15px;
    border: 10px solid rgba(0, 0, 0, 0.4);
    background-clip: padding-box;
  }
}

a {
  text-decoration: none;
  color: inherit;
  &:visited,
  &:active {
    color: inherit;
  }
}

// ellipsis hover 시 나오는 tooltip style
.ellipsis-tooltips {
  position: absolute;
  padding: 4px 8px;
  border-radius: 0;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: -0.6000000238418579px;
  line-height: 14px;
  background-color: #dadfe8;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  height: auto;
  text-align: left;
  overflow: hidden;
  word-break: break-all;
  z-index: 1;
  color: #272d34;
}
.pointer {
  cursor: pointer;
}
span.words{
  display: flex;
  span{
    display: inline-flex;
    overflow: hidden;
    &.first{
      justify-content: flex-start;
      max-width: calc(50% - 6px);
    }
    &.center{
      width: 12px;
    }
    &.end{
      justify-content: flex-end;
      max-width: calc(50% - 6px);
    }
  }
}